import { NgModule } from '@angular/core';
import { RouterModule , Routes} from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { PacotesComponent } from './pages/pacotes/pacotes.component';
import { DetailComponent } from './pages/pacotes/detail/detail.component';
import { DocumentacoesComponent } from './pages/documentacoes/documentacoes.component';
import { NoutFoundComponent } from './pages/nout-found/nout-found.component';
import { FaleConoscoComponent } from './pages/fale-conosco/fale-conosco.component';
import { AutorizacaoNacionalComponent } from './pages/autorizacoes/autorizacao-nacional.component';
import { AutorizacaoInternacionalComponent } from './pages/autorizacoes/autorizacao-internacional.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, data: { state: 'home'} },
  { path: 'pacotes-nacionais', component: PacotesComponent },
  { path: 'pacotes-internacionais', component: PacotesComponent },
  { path: 'aapvr', component: PacotesComponent },
  { path: 'destino', component: DetailComponent },
  { path: 'documentacoes', component: DocumentacoesComponent },
  { path: 'fale-conosco', component: FaleConoscoComponent },
  { path: 'autorizacao-nacional', component: AutorizacaoNacionalComponent },
  { path: 'autorizacao-internacional', component: AutorizacaoInternacionalComponent },
  { path: '404', component: NoutFoundComponent},
  // { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { UtilService } from "../../providers/util.service";
import swal from "sweetalert2";

@Component({
  selector: "whatsapp",
  templateUrl: "./whatsApp.component.html",
  styleUrls: ["./whatsApp.component.scss"],
})
export class WhatAppComponent implements OnInit {
  listDestinos = [];

  constructor(private util: UtilService) {}

  ngOnInit() {}
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SlickModule } from "ngx-slick";
import { BuscaPacotesComponent } from "./layout/busca-pacotes/busca-pacotes.component";
import { ListCardComponent } from "./layout/list-card/list-card.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { WhatAppComponent } from "./layout/whatsApp/whatsApp.component";
import { LoadingComponent } from "./layout/loading/loading.component";
import { PacotesComponent } from "./pages/pacotes/pacotes.component";
import { BannerComponent } from "./layout/banner/banner.component";
import { PaginationComponent } from "./layout/pagination/pagination.component";
import { DetailComponent } from "./pages/pacotes/detail/detail.component";
import { DocumentacoesComponent } from "./pages/documentacoes/documentacoes.component";
import { HightlightPipe } from "./pipes/hightlight.pipe";
import { NoutFoundComponent } from "./pages/nout-found/nout-found.component";
import { FaleConoscoComponent } from "./pages/fale-conosco/fale-conosco.component";
import { AutorizacaoNacionalComponent } from "./pages/autorizacoes/autorizacao-nacional.component";
import { AutorizacaoInternacionalComponent } from "./pages/autorizacoes/autorizacao-internacional.component";

import { DisneyModule } from "./pages/disney/disney.module";
import { NgxMaskModule } from "ngx-mask";
import { BannerPubComponent } from "./layout/banner-pub/banner-pub.component";

const options = {
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    BuscaPacotesComponent,
    ListCardComponent,
    FooterComponent,
    WhatAppComponent,
    LoadingComponent,
    PacotesComponent,
    BannerComponent,
    PaginationComponent,
    DetailComponent,
    DocumentacoesComponent,
    HightlightPipe,
    NoutFoundComponent,
    FaleConoscoComponent,
    AutorizacaoNacionalComponent,
    AutorizacaoInternacionalComponent,
    BannerPubComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    DisneyModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(options),
    SlickModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  listDestinos = [];

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {
  }

  sendNews(form: FormGroup) {
    
    this.listDestinos = this.util.getDestinosNews(form.value);
    form.value.destinos = this.listDestinos;
    

    this.util.postNews(form.value)
    .subscribe(data=>{
     
      if (data.status = 1) {
        swal({
          title: 'Ok!',
          text: data.message,
          type: 'success'
        });
      }
      else {
        swal({
          title: 'Ops!',
          text: data.message,
          type: 'error'
        });
      }
      
      form.reset();


    });

  }

}

import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Output() sendPage = new EventEmitter();
  @Input() totalPages;

  currentPage = 1;
  pages = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

    this.createPages();

  }

  createPages() {

    for (let i=1; i <= this.totalPages; i++) {
      this.pages.push(i);
    }


  }

  updatePage(page: number) {
    this.currentPage = page;
    this.sendPage.emit(page);
    $('html').animate({
      scrollTop: $('.packages-travel').offset().top - 45
    }, 1000);
  }

  

}

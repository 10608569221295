import { Component, OnInit } from "@angular/core";
import { PacotesService } from "../../providers/pacotes.service";
import { map } from "rxjs/operators";
import { BASE_URL, URL_BANNERS, URL_PUB } from "../../config";
import { Http } from "@angular/http";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  typeid = 2;
  filterPackages = [];
  listPackages = [];
  slides = [
    {
      background: `${URL_BANNERS}/12.png`,
      titulo: 'Pacote <br/> Disney <small class="last">2022</small>',
      descricao: "Embarque para Orlando com a GG Turismo!",
      logo: null,
    },
  ];
  listPublicidade = [
    {
      nome: "DHL",
      imagem: `${URL_PUB}/banner-dhl.png`,
      url: "http://wa.me/5524999751828",
    },
    {
      nome: "Affinity",
      imagem: `${URL_PUB}/banner-Affinity.png`,
      url: "https://www.goaffinity.com.br/GGTURISMO",
    },
    // {
    //   nome: "ShpUsa",
    //   imagem: `${URL_PUB}/banner-ShopUSA.png`,
    //   url: "https://www.shoppusa.com.br/chip-pre-pago-nos-eua/?utm_source=agencias&utm_medium=ggturismo&utm_campaign=banner",
    // },
    {
      nome: "MSC",
      imagem: `${URL_PUB}/banner-msc.png`,
      url: "https://www.msccruisespartners.com/b84aa1ca-da30-4089-9ce9-3570dc5bf4f6#/",
    },
  ];

  constructor(private _pacotesService: PacotesService, private http: Http) {}

  ngOnInit() {
    this.filterPackages = this._pacotesService.typesPackages;
    this.loadPackages();
    this.loadBanners();
  }

  loadPackages() {
    const page = 1;

    this._pacotesService.getPackages(page, this.typeid).subscribe(
      (result) => {
        this.listPackages = result.data;
      },
      (error) => {
        console.log("Ops" + error);
      }
    );
  }

  loadBanners() {
    const url = `${BASE_URL}/banner/read.php`;

    this.http
      .get(url)
      .pipe(map((res) => res.json()))
      .subscribe((result) => {
        // this.slides = result.data;
      });
  }

  filterPackage(typeid: any) {
    this.loadPackages();
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html"
})
export class BannerComponent implements OnInit {
  @Input() data = {
    bg: "",
    titulo: "",
    icon: false,
    descricao: ""
  };

  @Input() icon = false;

  constructor() {}

  ngOnInit() {}
}

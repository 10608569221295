import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BASE_URL } from '../config';
import { map } from 'rxjs/operators/';

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoInternacionalService {

  constructor(
    private http: Http
  ) { }

  postAutorizacao(data: object) {

    const url = `${BASE_URL}/autorizacaointernacional/create.php`;

    return this.http.post(url, data).pipe(
      map(res => res.json())
    );

  }

}

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BASE_URL } from '../config';
import { map } from 'rxjs/operators/';

@Injectable({
  providedIn: 'root'
})
export class FaleconoscoService {

  constructor(
    private http: Http
  ) { }

  postContact(data: object) {
    
    const url = `${BASE_URL}/contato/create.php`;

    return this.http.post(url, data).pipe(
      map(res => res.json())
    );

  }

  loadDestinos() {

    const url = `${BASE_URL}/destino/read.php`;

    return this.http.get(url)
    .pipe(
      map(
        res => res.json()
      )
    )

  }

}

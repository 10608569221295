import { Component, OnInit } from '@angular/core';
import { FaleconoscoService } from '../../providers/faleconosco.service';
import { FormGroup } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  listDestinos = [];

  constructor(
    private contatoService: FaleconoscoService
  ) { }

  ngOnInit() {

    this.contatoService.loadDestinos()
    .subscribe( result => {
      this.listDestinos = result.data;
    });

  }

  postContato(form: FormGroup) {

    form.value.destino = Number(form.value.destino);
    
    if (form.valid) {
      this.contatoService.postContact(form.value)
      .subscribe(data => {

        if (data.status = 1) {
          swal({
            title: 'Ok!',
            text: data.message,
            type: 'success'
          });
        }
        else {
          swal({
            title: 'Ops!',
            text: data.message,
            type: 'error'
          });
        }
        
        form.reset();

      });
    }

  }

}

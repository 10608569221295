import { Component, OnInit } from "@angular/core";
import { AutorizacaoInternacionalService } from "../../providers/autorizacaointernacional.service";
import { FormGroup } from "@angular/forms";
import swal from "sweetalert2";

@Component({
  selector: "app-autorizacao-internacional",
  templateUrl: "./autorizacao-internacional.component.html",
})
export class AutorizacaoInternacionalComponent implements OnInit {
  constructor(private autorizacaoService: AutorizacaoInternacionalService) {}

  ngOnInit() {}

  postAutorizacaoInternacional(form: FormGroup) {
    if (form.valid) {
      this.autorizacaoService.postAutorizacao(form.value).subscribe((data) => {
        if ((data.status = 1)) {
          swal({
            title: "Ok!",
            text: data.message,
            type: "success",
            confirmButtonText: `<a style="color: #FFF;" href="https://www.ggturismo.com.br/api/autorizacaointernacional/${data.arquivo}" target="_blank"><i class="fa fa-download"></i> Download</a>`,
          });
        } else {
          swal({
            title: "Ops!",
            text: data.message,
            type: "error",
          });
        }

        // form.reset();
      });
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { URL_BANNERS } from '../../config';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PacotesService } from '../../providers/pacotes.service';

@Component({
  selector: 'app-pacotes',
  templateUrl: './pacotes.component.html',
  styleUrls: ['./pacotes.component.scss']
})
export class PacotesComponent implements OnInit, OnDestroy {

  typeId = 1;
  page =  1;
  totalPages:number = 0;
  private sub: Subscription;
  private load = false;
  listPackages = [];

  banner: object = {
    bg: `${URL_BANNERS}/banner_pacotenacional.jpg`,
    titulo: 'Conheça nossos <br/> Pacotes Nacionais',
    descricao: 'Consulte aqui os melhores destinos para sua viagem.'
  };

  constructor(
    private router: Router,
    private pacotesService: PacotesService
  ) { }

  ngOnInit() {
    this.loadingPage();
  }

  loadingPage() {

    const route = this.router.url;

    if ( route === '/pacotes-internacionais' ) {

      this.typeId = 2;
      this.banner["bg"] = `${URL_BANNERS}/banner_pacoteinternacional.jpg`;
      this.banner["titulo"] = "Conheça nossos <br/> Pacotes Internacionais";

    }
    else if ( route === '/aapvr') {

      this.typeId = 0;
      this.banner["bg"] = `${URL_BANNERS}/5.jpg`;
      this.banner["titulo"] = "Pacotes AAPVR";
      this.banner["descricao"] = "Conheças os pacotes de viagem exclusivos para membros da <br/>Associação de Aposentados e Pensionistas de Volta Redonda";

    }

    this.sub = this.pacotesService.getPackages(this.page, this.typeId)
    .subscribe( result => {
      this.listPackages = result.data;
      this.totalPages = result.total;
    });

  }


  updatePage(page: number) {

    this.sub = this.pacotesService.getPackages(page, this.typeId)
    .subscribe( result => {
      this.listPackages = result.data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

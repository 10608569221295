import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preparacao',
  templateUrl: './preparacao.component.html',
  styleUrls: ['./preparacao.component.scss']
})
export class PreparacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

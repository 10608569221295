import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hightlight'
})
export class HightlightPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let keymodel = args;

    value = value.replace(new RegExp(keymodel, 'gi'), keymodel.bold());

    return value;

  }


}

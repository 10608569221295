import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() titulo: string;
  @Input() descricao: string;
  @Input() imagens: string[];
  currentActive = 0;
  imageSelected: string;

  slideConfig = {
    'slidesToShow': 6,
    'slidesToScroll': 6,
    arrows: true,
    nextArrow: '<i class="fa fa-arrow-right slick-next"></i>',
    prevArrow: '<i class="fa fa-arrow-left slick-prev"></i>',
    infinite: false,
    slide: '.item'
  }

  constructor() { }

  ngOnInit() {

    this.imageSelected = this.imagens[this.currentActive];

  }

  changePicture(index) {
    this.currentActive = index;
    this.imageSelected = this.imagens[index];
  }

}

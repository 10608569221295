import { Subject, Subscription } from 'rxjs';
import { Component, OnDestroy }  from '@angular/core';
import { PacotesService } from '../../providers/pacotes.service';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-busca-pacote',
  templateUrl: './busca-pacotes.component.html',
  styleUrls: ['./busca-pacotes.component.scss']
})
export class BuscaPacotesComponent implements OnDestroy {

  model: string = '';
  sub: Subscription;
  modelChanged: Subject<string> = new Subject<string>();
  autoCompleteList = [];

  constructor(
    private _pacotesService: PacotesService,
    private router: Router
  )
  {

    this.sub = this.modelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      map(val => {

        if (val != '') {

          this._pacotesService.searchPackagebyName(val)
          .subscribe(data => {
            this.autoCompleteList = data;
          });
        }

      })
    )
    .subscribe();

  }

  changed(text: string) {
    this.modelChanged.next(text);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parque-universal',
  templateUrl: './parque-universal.component.html',
  styleUrls: ['./parque-universal.component.scss']
})
export class ParqueUniversalComponent implements OnInit {

  universal = {
    titulo: 'UNIVERSAL STUDIOS',
    descricao: 'Este parque é todo inspirado no cinema e nas séries de tv e a diversão rola em quase todas as atrações. Viajando com o e.T. Ou encarando um furacão, você vai se sentir dentro de um filme de verdade. É adrenalina pura!',
    imagens: [
      '../../assets/images/parques/universal-studios/1.jpg',
      '../../assets/images/parques/universal-studios/2.jpg',
      '../../assets/images/parques/universal-studios/3.jpg',
      '../../assets/images/parques/universal-studios/4.jpg',
      '../../assets/images/parques/universal-studios/5.jpg',
      '../../assets/images/parques/universal-studios/6.jpg',
      '../../assets/images/parques/universal-studios/7.jpg',
      '../../assets/images/parques/universal-studios/8.jpg',
    ]
  }

  islands = {
    titulo: 'ISLANDS OF ADVENTURE',
    descricao: 'Este parque é todo inspirado no cinema e nas séries de tv e a diversão rola em quase todas as atrações. Viajando com o e.T. Ou encarando um furacão, você vai se sentir dentro de um filme de verdade. É adrenalina pura!',
    imagens: [
      '../../assets/images/parques/islands-of-adventure/1.jpg',
      '../../assets/images/parques/islands-of-adventure/2.jpg',
      '../../assets/images/parques/islands-of-adventure/3.jpg',
      '../../assets/images/parques/islands-of-adventure/4.jpg',
      '../../assets/images/parques/islands-of-adventure/5.jpg',
      '../../assets/images/parques/islands-of-adventure/6.jpg',
      '../../assets/images/parques/islands-of-adventure/7.jpg',
      '../../assets/images/parques/islands-of-adventure/8.jpg',
    ]
  }


  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { BASE_URL } from "../config";

@Injectable({
  providedIn: 'root'
})
export class PacotesService {

  constructor(
    private http: Http
  ) { }


  get typesPackages() {

    const tipos = [
      {id: 1, name: 'Nacionais'},
      {id: 2, name: 'Internacionais'},
      {id: 0, name: 'AAPVR'}
    ];

    return tipos;

  }

  searchPackages(value: string) {

    return value;

  }

  getPackages(page, tipo) {
    
    const url = `${BASE_URL}/pacote/read.php?page=${page}&tipo=${tipo}`;
    
    return this.http.get(url)
    .pipe(
      map(
        res => res.json()
      )
    );
  }

  getDetailPackage(id: number) {

    const url = `${BASE_URL}/pacote/read_detail.php?id=${id}`;

    return this.http.get(url)
    .pipe(
      map(
        res => res.json()
      )
    );

  }

  searchPackagebyName(search: string) {

    const url = `${BASE_URL}/pacote/read_search.php?nome=${search}`;

    return this.http.get(url)
    .pipe(
      map(
        res => res.json()
      )
    );

  }


}

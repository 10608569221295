import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { BASE_URL } from '../config';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private http: Http
  ) { }

  postNews(obj: any) {

    const url = `${BASE_URL}/cadastro/create.php`;

    return this.http.post(url, obj)
    .pipe(
      map(
        res => res.json()
      )
    );

  }


  getDestinosNews(values) {

    const list = [];

    if (values.brasil) {
      list.push("Brasil");
    }

    if (values["america_norte"]) {
      list.push("América do Norte");
    }

    if (values["america_sul"]) {
      list.push("América do Sul");
    }

    if (values["america_central"]) {
      list.push("América Central");
    }

    if (values["europa"]) {
      list.push("Europa");
    }

    if (values["africa_oriente"]) {
      list.push("África e Oriente Médio");
    }

    if (values["asia"]) {
      list.push("Ásia");
    }

    return list;


  };


}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { DisneyComponent } from "./disney.component";
import { PreparacaoComponent } from "./preparacao/preparacao.component";
import { CondicoesComponent } from "./condicoes/condicoes.component";
import { InformacoesComponent } from "./informacoes/informacoes.component";
import { ParqueDisneyComponent } from "./parque-disney/parque-disney.component";
import { GalleryComponent } from "../../layout/gallery/gallery.component";
import { SlickModule } from "ngx-slick";
import { ParqueUniversalComponent } from "./parque-universal/parque-universal.component";
import { AventurasComponent } from "./aventuras/aventuras.component";
import { DaybydayComponent } from "./daybyday/daybyday.component";

const routes: Routes = [
  {
    path: "disney",
    component: DisneyComponent,
    children: [
      {
        path: "",
        component: PreparacaoComponent,
      },
      {
        path: "preparacao",
        component: PreparacaoComponent,
      },
      {
        path: "condicoes-gerais",
        component: CondicoesComponent,
      },
      {
        path: "day-by-day",
        component: DaybydayComponent,
      },
      {
        path: "informacoes",
        component: InformacoesComponent,
      },
      {
        path: "parques-disney",
        component: ParqueDisneyComponent,
      },
      {
        path: "parques-universal",
        component: ParqueUniversalComponent,
      },
      {
        path: "aventuras-que-molham",
        component: AventurasComponent,
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), SlickModule.forRoot()],
  declarations: [
    DisneyComponent,
    PreparacaoComponent,
    CondicoesComponent,
    DaybydayComponent,
    InformacoesComponent,
    ParqueDisneyComponent,
    GalleryComponent,
    ParqueUniversalComponent,
    AventurasComponent,
  ],
  exports: [RouterModule],
})
export class DisneyModule {}

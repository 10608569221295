import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from '../../../../node_modules/rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isHome: boolean;
  sub: Subscription;
  logo = 'assets/images/logo.png';

  constructor(
    private router: Router,
    private location: Location
  )
  {

    this.sub = this.router.events.subscribe((val) => {

      if (location.path() === '') {
        this.isHome = true;
        this.logo = 'assets/images/logo-branco.png';
      }
      else {
        this.isHome = false;
        this.logo = 'assets/images/logo.png';
      }

    });
  }

  ngOnInit() {
    this.stickyHeader();
  }

  stickyHeader() {

    $(window).on('load scroll', function() {

      const position = $(this).scrollTop();

      if (position >= 60) {
        $('.main-header').addClass('sticky');
      } else {
        $('.main-header').removeClass('sticky');
      }

    });

    $('.bt-menu').click(function() {
      $('.nav-menu').toggleClass('enabled');
      $(this).toggleClass('fa-times fa-bars');
    });

    $('.nav-menu a').click(function() {

      if ($(this).parent().hasClass('enabled')) {
        $(this).parent().removeClass('enabled');
        $('.bt-menu').toggleClass('fa-times fa-bars');
      }

    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}

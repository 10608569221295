import { Component, OnInit, OnDestroy } from "@angular/core";
import { URL_BANNERS } from "../../../config";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { PacotesService } from "../../../providers/pacotes.service";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"]
})
export class DetailComponent implements OnInit, OnDestroy {
  sub: Subscription;
  contentDetail = [];

  infoDetail = {
    bg: `${URL_BANNERS}/6.jpg`,
    titulo: "",
    descricao: ""
  };

  configSlick = {
    nextArrow: '<i class="fa fa-arrow-right slick-next"></i>',
    prevArrow: '<i class="fa fa-arrow-left slick-prev"></i>',
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    slide: ".item"
  };

  constructor(
    private router: ActivatedRoute,
    private pacoteService: PacotesService
  ) {}

  ngOnInit() {
    this.sub = this.router.queryParams.subscribe(data => {
      const id = data.id;

      this.pacoteService.getDetailPackage(id).subscribe(result => {
        this.contentDetail = result;
        this.infoDetail.titulo = result.nome;
        this.infoDetail.bg = result.imagem;
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aventuras',
  templateUrl: './aventuras.component.html',
  styleUrls: ['./aventuras.component.scss']
})
export class AventurasComponent implements OnInit {

  slide = {
    titulo: 'Parques Aquáticos',
    descricao: 'São três: aquatica, discovery cove e disney"s blizzard beach. São feitos com atrações molhadas e brinquedos pra lá de divertidos. O bom desses parques é que você pode aproveitar para pegar aquela cor: biquínis e sungas serão bem-vindos!',
    imagens: [
      'https://secure.cdn1.wdpromedia.com/resize/mwImage/1/900/360/75/dam/wdpro-assets/parks-and-tickets/destinations/magic-kingdom/magic-kingdomv3-00-full.jpg?1530194897145',
      'https://hips.htvapps.com/htv-prod/ibmig/cms/image/wesh/40051302-magic-kingdom.jpg?crop=1.00xw:0.951xh;0,0.0467xh&resize=900:*',
      'https://imagesvc.timeincapp.com/v3/mm/image?url=https%3A%2F%2Fcdn-image.travelandleisure.com%2Fsites%2Fdefault%2Ffiles%2Fstyles%2F1600x1000%2Fpublic%2F1492024180%2Fopener-magic-kingdom-MAGICKING0417.jpg%3Fitok%3D5PXAr-RU&w=700&q=85',
      'https://secure.cdn1.wdpromedia.com/resize/mwImage/1/630/354/75/dam/wdpro-assets/parks-and-tickets/entertainment/magic-kingdom/magic-kingdom-welcome/magic-kingdom-welcome-04.jpg?1520708281105',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150',
      'http://via.placeholder.com/350x150'
    ]
  }

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-daybyday",
  templateUrl: "./daybyday.component.html",
  styleUrls: ["./daybyday.component.scss"],
})
export class DaybydayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner-pub',
  templateUrl: './banner-pub.component.html',
  styleUrls: ['./banner-pub.component.scss']
})
export class BannerPubComponent implements OnInit {

  @Input() listPub: object[];
  private loader = false;

  constructor() { }

  ngOnInit() {
  }

}

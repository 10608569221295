import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parque-disney',
  templateUrl: './parque-disney.component.html',
  styleUrls: ['./parque-disney.component.scss']
})
export class ParqueDisneyComponent implements OnInit {

  magicKigdom = {
    titulo: 'Magic Kingdom',
    descricao: 'Esse é o parque mais clássico da disney e é nele que está o castelo da cinderela. Ao todo são sete "cidades" com 43 atrações incríveis. Mas o que você não pode perder é a parada mais famosa do complexo disney, onde as luzes do magic kingdom se apagam, dando destaque aos carros iluminados. O desfile termina em frente ao castelo, onde rola a queima de fogos e uma projeção incrível contando toda a história desse mundo encantado.',
    imagens: [
      '../../assets/images/parques/magic-kingdom/1.jpg',
      '../../assets/images/parques/magic-kingdom/2.jpg',
      '../../assets/images/parques/magic-kingdom/3.jpg',
      '../../assets/images/parques/magic-kingdom/4.jpg',
      '../../assets/images/parques/magic-kingdom/5.jpg',
      '../../assets/images/parques/magic-kingdom/6.jpg',
      '../../assets/images/parques/magic-kingdom/7.jpg',
      '../../assets/images/parques/magic-kingdom/8.jpg',
      '../../assets/images/parques/magic-kingdom/9.jpg',
      '../../assets/images/parques/magic-kingdom/10.jpg',
      '../../assets/images/parques/magic-kingdom/11.jpg',
      '../../assets/images/parques/magic-kingdom/12.jpg',
      '../../assets/images/parques/magic-kingdom/13.jpg',
    ]
  }

  Epcot = {
    titulo: 'Epcot',
    descricao: 'Aqui, com uma única volta, você vai conhecer 11 países diferentes (eles possuem as comidas típicas e vilarejos que remetem a diferentes lugares). Não deixe de tirar a sua foto com a imensa bola prateada, símbolo que identifica esse parque.',
    imagens: [
      '../../assets/images/parques/epcot/1.jpg',
      '../../assets/images/parques/epcot/2.jpg',
      '../../assets/images/parques/epcot/3.jpg',
      '../../assets/images/parques/epcot/4.jpg',
      '../../assets/images/parques/epcot/5.jpg',
      '../../assets/images/parques/epcot/6.jpg',
      '../../assets/images/parques/epcot/7.jpg',
      '../../assets/images/parques/epcot/8.jpg',
    ]
  }

  animalKingdom = {
    titulo: 'ANIMAL KINGDOM',
    descricao: 'O contato com a natureza acontece a todo instante. E, por esse motivo, é um lugar muito agradável. Dá vontade de passar um dia inteiro curtindo. E ainda tem uma novidade: vamos também conhecer o mundo avatar. São 21 atrações, em que, você irá sentir muita adrenalina.',
    imagens: [
      '../../assets/images/parques/animal-kingdom/1.jpg',
      '../../assets/images/parques/animal-kingdom/2.jpg',
      '../../assets/images/parques/animal-kingdom/3.jpg',
      '../../assets/images/parques/animal-kingdom/4.jpg',
      '../../assets/images/parques/animal-kingdom/5.jpg',
      '../../assets/images/parques/animal-kingdom/6.jpg',
      '../../assets/images/parques/animal-kingdom/7.jpg',
    ]
  }

  hollywood = {
    titulo: 'HOLLYWOOD STUDIOS',
    descricao: 'É neste parque que você vai entender como é que rola um set de filmagens. São carros que explodem e torres que desmontam. Aproveite para fazer muitas fotos.',
    imagens: [
      '../../assets/images/parques/hollywood-studios/1.jpg',
      '../../assets/images/parques/hollywood-studios/2.jpg',
      '../../assets/images/parques/hollywood-studios/3.jpg',
      '../../assets/images/parques/hollywood-studios/4.jpg',
      '../../assets/images/parques/hollywood-studios/5.jpg',
      '../../assets/images/parques/hollywood-studios/6.jpg',
      '../../assets/images/parques/hollywood-studios/7.jpg',
      '../../assets/images/parques/hollywood-studios/8.jpg',
    ]
  }

  constructor() { }

  ngOnInit() {
  }

}
